/* General container height adjustment */
.react-tel-input .form-control {
  height: 30px; /* Adjust height here */
  font-size: 14px; /* Adjust font size for better fit */
  padding: 4px 8px; /* Adjust padding to match height */
  border-radius: 4px; /* Optional: Adjust border radius */
}

/* Country flag dropdown adjustments */
.react-tel-input .flag-dropdown {
  height: 30px; /* Match input height */
  width: 40px; /* Adjust as needed for flag dropdown */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}

/* Flag image inside dropdown */
.react-tel-input .flag-dropdown .selected-flag {
  height: 100%; /* Ensure flag stretches properly */
}

/* Ensure dropdown arrow aligns well */
.react-tel-input .flag-dropdown .arrow {
  margin-top: 0; /* Align the dropdown arrow properly */
}


.react-tel-input {
  width: 300px; /* Adjust to desired width */
}

/* Ensure the input field fits within the specified width */
.react-tel-input .form-control {
  width: 100%; /* Make input field fit container */
  box-sizing: border-box; /* Prevent overflow due to padding */
}

.parent-container {
  max-width: 300px; /* Restrict the parent's width */
  margin: 0 auto;   /* Center it if needed */
}