/* .fullscreen-bg {
  background-image: url("D:\GitHub\Organflow-2.0\Images/img1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
} */

.fullscreen-bg {
  background-size: cover;
  max-height: 350px;
}
